import axios from "axios";
import { Toast } from "vant";
// import Qs from "qs";
// import $dt from '../utils/base.js'
import store from "@/store";
import vm from "@/main.js";
let dt = {
  apiUrl: process.env.VUE_APP_API_URL,
};
const httpService = (input) => {
  if (process.env.NODE_ENV !== "dev") {
    input.url = /^http:\/\/|^https:\/\//.test(input.url)
      ? input.url
      : (dt.apiUrl || "") + input.url;
  }

  // 构建 headers

  return new Promise((resolve) => {
    axios({
      url: input.url,
      timeout: 100000,
      data: input.body,
      params: input.params,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer" + " " + store.state.token,
      },
      method: input.method || "get",
    })
      .then((res) => {
        if (input.url.indexOf('api/staff/get_token') === -1 && input.url.indexOf('api/wechat') === -1) {
          if (res.data.code === 0) {
            resolve(res.data);
          } else {
            if (res.data.code === 401) {
              Toast.fail("请重新登录");
              resolve(res.data);
              vm.$router.push({ path: "/login" });
            } else {
              Toast.fail("接口异常");
              resolve(res.data);
            }

          }
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        console.log(err, 111)

        Toast.fail("接口异常111");
        // if (err.response.data) {
        //   Toast(err.response.data.msg);
        // }
        // if (err.response.status == 404) {
        //   vm.$router.push({ path: "/error404" });
        // } else if (err.response.status == 401) {
        //   vm.$router.push({ path: "/error401" });
        // } else if (err.response.status == 403) {
        //   vm.$router.push({ path: "/error403" });
        // } else {
        //   vm.$router.push({ path: "/error" });
        // }
        // if (err.response.code === "ECONNABORTED") {
        // Message({
        //     showClose: true,
        //     message: '请求超时，请检查您的网络',
        //     type: 'info'
        // })
        resolve(err);
      });
  });
};
httpService.get = (url, params) => {
  return httpService({
    url,
    params,
  });
};

httpService.post = (url, params) => {
  return httpService({
    url,
    body: params,
    method: "post",
  });
};
export default httpService;
