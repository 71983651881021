import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/rem";
import dt from "./plugins/utils";
// 引入公共组件
import "./plugins/commonComponents";
Vue.prototype.$dt = dt;
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

require("./assets/css/reset.css");
require("./assets/css/base.less");
require("./assets/css/common-person.less");
import baiduMap from 'vue-baidu-map'
Vue.use(baiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'M53VsY6rYerqqOgYQxv9Bu861VXXnhef'
})


router.beforeEach((to, from, next) => {
  let routerList = router.options.routes[0]
    ? router.options.routes[0].children
    : [];
  let routersName = routerList.map((item) => {
    return item.path;
  });
  if (routersName.indexOf(to.path) === -1) {
    next({ path: "/error404" });
  } else {
    next();
  }
});
export default new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
