import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import httpService from "@/plugins/http";
import sha1 from "sha1";
import { Toast } from "vant";
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
// const dictionary = JSON.parse(sessionStorage.getItem("dictionary")) || {};

const store = new Vuex.Store({
  state: {
    isShow: false,
    approveUserId: "",
    userInfo: {},
    token: "",
    areaTree: [], // 区域
    // people_nation: dictionary.people_nation || [], // 民族
    // people_political: dictionary.people_political || [],
    // hida_type: dictionary.hida_type || [], //隐患类型
    // hida_level: dictionary.hida_level || [], //隐患级别
    // event_type: dictionary.event_type || [], //任务类型
    // area_type: dictionary.area_type || [], // 区域类型,
    // people_sex: dictionary.people_sex || [], // 性别,
    // people_mtype: dictionary.people_mtype || [], // 管理类别,
    // people_politics: dictionary.people_politics || [], // 政治面貌,
    // people_edu: dictionary.people_edu || [], // 文化程度,
    // people_mss: dictionary.people_mss || [], // 兵役状况,
    // people_care: dictionary.people_care || [], // 优抚对象,
    // people_health: dictionary.people_health || [], // 健康状况,
    // people_faith: dictionary.people_faith || [], // 宗教信仰,
    // people_fps: dictionary.people_fps || [], // 计划生育,
    // people_eins: dictionary.people_eins || [], // 养老保险,
    // people_mins: dictionary.people_mins || [], // 医疗保险,
    // people_poverty: dictionary.people_poverty || [], // 平困状况,
    // people_rwh: dictionary.people_rwh || [], // 与户主关系,
    // house_attr: dictionary.house_attr || [], // 房屋属性,
    // house_struct: dictionary.house_struct || [], // 房屋结构,
    // house_prt: dictionary.house_prt || [], // 产权类型,
    // unit_type: dictionary.unit_type || [], //  单位行业类型,
    // unit_nature: dictionary.unit_nature || [], // 单位性质,
    // staff_type: dictionary.staff_type || [], // 人员类型,
    // event_level: dictionary.event_level || [], // 事件等级
    // interview_type: dictionary.interview_type || [], // 走访类型
    // interview_target: dictionary.interview_target || [], // 走访对象
    // dictionary: {},// 字典值
    people_nation:  [], // 民族
    people_political: [],
    hida_type:[], //隐患类型
    hida_level: [], //隐患级别
    event_type:  [], //任务类型
    area_type:  [], // 区域类型,
    people_sex:  [], // 性别,
    people_mtype:  [], // 管理类别,
    people_politics:  [], // 政治面貌,
    people_edu:  [], // 文化程度,
    people_mss:  [], // 兵役状况,
    people_care:  [], // 优抚对象,
    people_health: [], // 健康状况,
    people_faith:  [], // 宗教信仰,
    people_fps:  [], // 计划生育,
    people_eins: [], // 养老保险,
    people_mins:[], // 医疗保险,
    people_poverty:[], // 平困状况,
    people_rwh: [], // 与户主关系,
    house_attr: [], // 房屋属性,
    house_struct: [], // 房屋结构,
    house_prt:  [], // 产权类型,
    unit_type: [], //  单位行业类型,
    unit_nature:  [], // 单位性质,
    staff_type: [], // 人员类型,
    event_level: [], // 事件等级
    interview_type:  [], // 走访类型
    interview_target:[], // 走访对象
    dictName: [
      "people_nation",
      "people_political",
      "hida_type",
      "hida_level",
      "event_type",
      "area_type",
      "people_sex",
      "people_politics",
      "people_edu",
      "people_mss",
      "people_care",
      "people_health",
      "people_faith",
      "people_fps",
      "people_eins",
      "people_mins",
      "people_poverty",
      "people_rwh",
      "house_attr",
      "house_struct",
      "house_prt",
      "unit_type",
      "unit_nature",
      "staff_type",
      "event_level",
      "interview_type",
      "interview_target",
    ],
  },
  mutations: {
    setApproveUserId(state, data) {
      state.approveUserId = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setToken(state, data) {
      state.token = data;
    },
    setAreaTree(state, data) {
      state.areaTree = data;
    },
    setBaseOptions: function (state) {
      httpService
        .post("/api/dict/get", {
          type: state.dictName,
        })
        .then((res) => {
          if (res.code === 0) {
            console.log(res.data, "res.data");
            for (let key in res.data) {
              res.data[key] = res.data[key].map((item) => {
                return {
                  value: item.value,
                  text: item.label,
                  label: item.label,
                };
              });
              state[key] = res.data[key]
            }
       
            // sessionStorage.setItem("dictionary", JSON.stringify(res.data));
          }
        });
    },
    showLoading(state) {
      state.isShow = true;
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.isShow = false;
    },
  },
  actions: {
    setApproveUserId({ commit }, data) {
      commit("setApproveUserId", data);
    },
    setUserInfo({ commit }, data) {
      commit("setUserInfo", data);
    },
    setToken({ commit }, data) {
      commit("setToken", data);
    },

    // 获取用户信息
    GetInfo({ commit }, data) {
      return new Promise((resolve) => {
        commit("setToken", "");
        // "15269874156 123456"
        let pwd = data.phone + data.pwd;
        httpService
          .post("/api/staff/get_token", {
            login_name: data.phone,
            pwd: sha1(pwd),
            open_id: data.open_id,
          })
          .then((res) => {
            if (res.code === 0) {
              commit("setToken", res.data.token);
              commit("setUserInfo", { ...res.data, loginAcc: data.phone });
              resolve(res);
            } else if (res.code === -2) {
              // 用户不存在
              Toast("用户不存在");
            } else if (res.code === -3) {
              Toast("密码不对");
            }
          });
      });
    },
    getAreaTree({ commit }) {
      httpService.post("/api/area/tree", {}).then((res) => {
        if (res.code === 0) {
          let treeList = res.data || [];
          commit("setAreaTree", treeList);
        }
      });
    },
  },
  plugins: [vuexLocal.plugin],
});
export default store;
