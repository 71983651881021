import Vue from "vue";
import Router from "vue-router";
import store from "../store";
const home = () => import("@/pages/home.vue");
const login = () => import("@/pages/login/index.vue");
const myhome = () => import("@/pages/myhome/index.vue");
const personInfo = () => import("@/pages/personInfo/index.vue");
const personInfoDetail = () => import("@/pages/personInfo/detail.vue");
const houseInfo = () => import("@/pages/houseInfo/index.vue");
const houseInfoDetail = () => import("@/pages/houseInfo/detail.vue");
const dangerInfo = () => import("@/pages/dangerInfo/index.vue");
const dangerInfoDetail = () => import("@/pages/dangerInfo/detail.vue");
const eventReporting = () => import("@/pages/eventReporting/index.vue");
const eventReportingDetail = () => import("@/pages/eventReporting/detail.vue");
const visitingTasks = () => import("@/pages/visitingTasks/index.vue");
const visitingTasksDetail = () => import("@/pages/visitingTasks/detail.vue");
const dispatchTasks = () => import("@/pages/dispatchTasks/index.vue");
const dispatchTasksDetail = () => import("@/pages/dispatchTasks/detail.vue");
const dispatchTasksWaitDetail = () =>
  import("@/pages/dispatchTasks/waitDetail.vue");
const dispatchTasksTaskTransfer = () =>
  import("@/pages/dispatchTasks/taskTransfer.vue");
const dispatchTasksTaskProcessing = () =>
  import("@/pages/dispatchTasks/taskProcessing.vue");

const attendanceClock = () => import("@/pages/attendanceClock/index.vue");
const attendanceMap = () => import("@/pages/attendanceClock/map.vue");

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/myhome",
      component: home,
      children: [
        {
          path: "/login",
          name: "login",
          meta: {
            title: "登录",
          },
          component: login,
        },
        {
          path: "/myhome",
          name: "myhome",
          meta: {
            title: "首页",
          },
          component: myhome,
        },
        {
          path: "/personInfo",
          name: "personInfo",
          meta: {
            title: "人员信息",
          },
          component: personInfo,
        },
        {
          path: "/personInfoDetail",
          name: "personInfoDetail",
          meta: {
            title: "人员信息详情",
          },
          component: personInfoDetail,
        },
        {
          path: "/houseInfo",
          name: "houseInfo",
          meta: {
            title: "房屋信息",
          },
          component: houseInfo,
        },
        {
          path: "/houseInfoDetail",
          name: "houseInfoDetail",
          meta: {
            title: "房屋信息详情",
          },
          component: houseInfoDetail,
        },

        {
          path: "/dangerInfo",
          name: "dangerInfo",
          meta: {
            title: "隐患信息",
          },
          component: dangerInfo,
        },
        {
          path: "/dangerInfoDetail",
          name: "dangerInfoDetail",
          meta: {
            title: "隐患详情",
          },
          component: dangerInfoDetail,
        },
        {
          path: "/eventReporting",
          name: "eventReporting",
          meta: {
            title: "事件上报",
          },
          component: eventReporting,
        },
        {
          path: "/eventReportingDetail",
          name: "eventReportingDetail",
          meta: {
            title: "事件上报详情",
          },
          component: eventReportingDetail,
        },
        {
          path: "/visitingTasks",
          name: "visitingTasks",
          meta: {
            title: "走访任务",
          },
          component: visitingTasks,
        },
        {
          path: "/visitingTasksDetail",
          name: "visitingTasksDetail",
          meta: {
            title: "走访任务详情",
          },
          component: visitingTasksDetail,
        },
        {
          path: "/dispatchTasks",
          name: "dispatchTasks",
          meta: {
            title: "派发任务",
          },
          component: dispatchTasks,
        },
        {
          path: "/dispatchTasksDetail",
          name: "dispatchTasksDetail",
          meta: {
            title: "已处理任务详情",
          },
          component: dispatchTasksDetail,
        },

        {
          path: "/dispatchTasksWaitDetail",
          name: "dispatchTasksWaitDetail",
          meta: {
            title: "未处理任务详情",
          },
          component: dispatchTasksWaitDetail,
        },
        {
          path: "/dispatchTasksTaskTransfer",
          name: "dispatchTasksTaskTransfer",
          meta: {
            title: "任务转交",
          },
          component: dispatchTasksTaskTransfer,
        },
        {
          path: "/dispatchTasksTaskProcessing",
          name: "dispatchTasksTaskProcessing",
          meta: {
            title: "任务处理",
          },
          component: dispatchTasksTaskProcessing,
        },
        {
          path: "/attendanceClock",
          name: "attendanceClock",
          meta: {
            title: "考勤打卡",
          },
          component: attendanceClock,
        },
        {
          path: "/attendanceMap",
          name: "attendanceMap",
          meta: {
            title: "考勤打卡地图",
          },
          component: attendanceMap,
        },

        {
          path: "/error404",
          name: "error404",
          meta: {
            title: "404",
          },
          component: () => import("@/pages/error404.vue"),
        },
        {
          path: "/error403",
          name: "error403",
          meta: {
            title: "403",
          },
          component: () => import("@/pages/error403.vue"),
        },
        {
          path: "/error401",
          name: "error401",
          meta: {
            title: "401",
          },
          component: () => import("@/pages/error401.vue"),
        },
        {
          path: "/error",
          name: "error",
          meta: {
            title: "error",
          },
          component: () => import("@/pages/error.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log(store.state.token, "store.state.token");
  if (store.state.token) {
    next() 
  } else if( to.path !== '/login'){
    next("/login");
  } else {
    next(); 
  }
});
export default router;
