<template>
    <div>
       <van-dialog v-model="show" title="请选择" show-cancel-button @confirm="confirm">
            <div class="dialog-warp">
                <!-- <van-search v-model="value" placeholder="请输入搜索关键词" /> -->
                <div>
                    <template v-if="list.length">
                        <van-radio-group v-model="selectId">
                            <van-cell-group v-for="(item) in list" :key="item[propItem.code]"> 
                            <van-cell :title="item[propItem.name]" clickable @click="handleClickRadio(item[propItem.code])">
                                <template #right-icon>
                                <van-radio :name="item[propItem.code]" />
                                </template>
                            </van-cell>
                            </van-cell-group>
                        </van-radio-group>
                    </template>
                    <div v-else style="padding:15px;">暂无数据</div>
                    <van-pagination
                        style="margin:15px 0;"
                        v-model="curPage"
                        :total-items="total"
                        :show-page-size="3"
                        :items-per-page="5"
                        force-ellipses
                        @change="handlePageSize"
                    />
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import httpService from "@/plugins/http";
import { Toast } from 'vant';
export default {
    name:"selectPop",
    data() {
        return {
            list: [],
            selectId: null,
            total: null,
            curPage: 1, // 当前页
            show:false
        }
    },
    props: {
        // 额外参数
        queryParams: {
           type: Object,
           default: () => { return {}}
        },
        requestUrl: {
            type: String,
            default: ""
        },
        propItem: {
            type:Object,
            default:() => {
              return {
                code:"id",
                name:"name"
              }
            }       
        }
    },
    methods:{
        handleClickRadio(id) {
          this.selectId = id;
        },
        init() {
          this.curPage = 1;
          this.total = null;
          this.selectId = null;
          this.list = [];
          this.show = true;
          this.getList();
        },
        async getList() {
            try {
                const { code, data,total } = await httpService.post(this.requestUrl, {
                    start_page: this.curPage,
                    size_per_page: 5,
                    ...this.queryParams
                });
                if (code === 0) {
                    this.total = total;
                    this.list = data;
                }
                } catch (error) {
                    console.log(error, "error");
                }
        },
        handlePageSize(page) {
            this.curPage = page;
             this.selectId = null
            this.getList()
        },
        confirm() {
          if (!this.selectId) {
            Toast("请选择")
            return
          }
          let obj = this.list.find(item => {return item.id === this.selectId})
          this.$emit('submit',obj)
          this.show = false;
        }
    }
}
</script>