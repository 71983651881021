import vm from "@/main.js";
let dt = {
  goBack() {
    vm.$router.go(-1);
  },
  changeCodeName(dictName, code) {
    if (!code) return;
    let list = vm.$store.state[dictName] || []

    let obj = list.find((item) => {
      return item.value == code;
    });
    if (obj) {
      return obj.label;
    }
  },
  formatDate(date) {
    let result = date
    if (result && result.indexOf("0000-00-00") > -1) {
      result = ""
    }
    return result
  }
};
export default dt;
