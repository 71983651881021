<template>
  <div id="app">
    <router-view />
    <!-- <van-loading v-show="isShow" size="24px" color="#1989fa" vertical
      >加载中...</van-loading
    > -->
  </div>
</template>

<script>
import { mapState } from "vuex";
// import httpService from "@/plugins/http";
// import {getAuthorize,getAccessToken} from "./utils/sdkService.js"
export default {
  name: "app",
  data() {
    return {
    
    };
  },
  computed: {
    ...mapState(["isShow"]),
  },
  created() {
    // console.log(11111)
  },
  mounted() {

  },
  methods: {
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#app {
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: HarmonyOS Sans SC;
  // color: #666;
}
.van-loading {
  position: absolute;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
  .van-loading__spinner {
    color: rgb(25, 137, 250);
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -21px;
    width: 100%;
    text-align: center;
    position: absolute;
  }
  .van-loading__text {
    position: absolute;
    top: 50%;
  }
}
</style>
